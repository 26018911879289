import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

const LoadingSpinner = () => (
  <div className="text-center my-5">
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only"></span>
    </div>
  </div>
);

const ErrorMessage = ({ message }) => (
  <div className="text-center my-5">
    <p className="text-danger">{message}</p>
  </div>
);

export default function ProjectDetail() {
  const { slug } = useParams();
  const [project, setProject] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await fetch(
          `https://cms.petrazarecka.cz/wp-json/wp/v2/nemovitost?slug=${slug}&_embed`
        );

        if (!response.ok) {
          throw new Error(`Error fetching project: ${response.status}`);
        }

        const data = await response.json();
        if (data.length === 0) {
          throw new Error("Project not found.");
        }

        const projectDetails = data[0];
        const galleryImages =
          projectDetails.acf?.property_photos?.map((photo) => ({
            url: photo.url || "default-bg.jpg",
            alt: photo.alt || "Obrázek projektu",
          })) || [];

        setProject({
          id: projectDetails.id,
          slug: projectDetails.slug,
          title:
            projectDetails.title?.rendered || "Název projektu není k dispozici",
          content: projectDetails.content?.rendered || "",
          investor: projectDetails.acf?.investor,
          type: projectDetails.acf?.property_type || "Neurčený typ",
          description: projectDetails.acf?.property_description || "Bez popisu",
          gallery: galleryImages,
          completionDate: projectDetails.acf?.datum_dokonceni,
          price: projectDetails.acf?.property_price || "Cena na vyžádání",
          web: projectDetails.acf?.web || "",
          address:
            projectDetails.acf?.property_adress?.city || "Město nezadáno",
        });

        setBackgroundImage(galleryImages[0]?.url || "default-bg.jpg");
      } catch (error) {
        console.error(error.message);
        setError(error.message);
      }
    };

    fetchProject();
  }, [slug]);

  useEffect(() => {
    const fetchRecentPosts = async () => {
      try {
        const response = await fetch(
          `https://cms.petrazarecka.cz/wp-json/wp/v2/nemovitost?_embed&per_page=3&orderby=date&order=desc`
        );

        if (!response.ok) {
          throw new Error(`Error fetching recent posts: ${response.status}`);
        }

        const data = await response.json();
        const formattedRecentPosts = data
          .filter((post) => post.slug !== slug) // Exclude only the current project
          .map((post) => ({
            id: post.id,
            slug: post.slug,
            title: post.title?.rendered || "Název projektu není k dispozici",
            date: new Date(post.date).toLocaleDateString("cs-CZ"),
            image: post.acf?.property_photos?.[0]?.url || "default-bg.jpg",
          }));

        setRecentPosts(formattedRecentPosts);
      } catch (error) {
        console.error(error.message);
        setError(error.message);
      }
    };

    fetchRecentPosts();
  }, [slug]);

  if (error) {
    return <ErrorMessage message={error} />;
  }

  if (!project) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${backgroundImage || "default-bg.jpg"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12 text-center">
              <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark mt-4">
                {project.title}
              </h5>
              <span className="badge bg-primary me-1">{project.type}</span>
              <ul className="list-inline text-center mt-4">
                {project.investor && (
                  <li className="list-inline-item mx-4">
                    <span className="text-white-50 d-block">Investor</span>
                    <span className="text-white title-dark">
                      {project.investor}
                    </span>
                  </li>
                )}
                {project.completionDate && (
                  <li className="list-inline-item mx-4">
                    <span className="text-white-50 d-block">Rok dokončení</span>
                    <span className="text-white title-dark">
                      {project.completionDate}
                    </span>
                  </li>
                )}
                {project.price && (
                  <li className="list-inline-item mx-4">
                    <span className="text-white-50 d-block">Cena</span>
                    <span className="text-white title-dark">
                      {isNaN(project.price)
                        ? project.price
                        : new Intl.NumberFormat("cs-CZ").format(project.price) +
                          " Kč"}
                    </span>
                  </li>
                )}
              </ul>

              {project.web && (
                <a
                  href={project.web}
                  className="btn btn-secondary mt-4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Navštivte web projektu
                </a>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-8 col-md-7">
              <div className="card border-0 shadow rounded-3 overflow-hidden">
                <div className="card-body">
                  <h5 className="mb-3">Popis projektu</h5>
                  <p>{project.description}</p>
                  <div
                    dangerouslySetInnerHTML={{ __html: project.content }}
                  ></div>
                </div>
              </div>

              {project.gallery.length > 0 && (
                <div className="mt-5">
                  <h5 className="mb-3">Fotky projektu</h5>
                  <div className="row">
                    {project.gallery.map((photo, index) => (
                      <div className="col-md-4 col-sm-6 mb-3" key={index}>
                        <img
                          src={photo.url}
                          alt={photo.alt}
                          className="img-fluid rounded shadow"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="col-lg-4 col-md-6 col-12">
              <div className="card bg-white p-4 rounded-3 shadow sticky-bar">
                <div>
                  <h6 className="pt-2 pb-2 bg-light rounded-3 text-center">
                    Další projekty
                  </h6>
                  {recentPosts && recentPosts.length > 0 ? (
                    recentPosts.map((item) => (
                      <div
                        className="d-flex align-items-center mt-4"
                        key={item.id}
                      >
                        <img
                          src={item.image || "default-image.jpg"}
                          className="img-fluid avatar avatar-small rounded"
                          alt={item.title || "Další projekt"}
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                          }}
                        />
                        <div className="flex-1 ms-3">
                          <Link
                            to={`/projekt-detail/${item.slug}`}
                            className="d-block title text-dark"
                          >
                            {item.title || "Neznámý projekt"}
                          </Link>
                          <span className="text-muted">
                            {item.date || "Datum není k dispozici"}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-center">
                      Žádné další projekty k zobrazení
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
